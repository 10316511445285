import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./Hero.css";
import heroimage1 from "../../Assets/Service/brain.png";
import { SiCodemagic } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import "animate.css";

function Hero() {
  const navigate = useNavigate();

  // function generateParticles() {
  //   const particles = [];
  //   const colors = ["#B8AEF6", "#00FFFF"]; 
  //   const numParticles = 12;
  
  //   for (let i = 0; i < numParticles; i++) {
  //     const size = Math.floor(Math.random() * 10) + 5; 
  //     const opacity = Math.random() * 0.5 + 0.3; 
  //     const color = colors[Math.floor(Math.random() * colors.length)]; 
  //     const xPos = Math.random() * window.innerWidth; 
  //     const yPos = Math.random() * window.innerHeight; 
  
  //     particles.push(
  //       <div
        
  //         className="particle"
  //         key={i}
  //         style={{
  //           width: size + "px",
  //           height: size + "px",
  //           opacity: opacity,
  //           backgroundColor: color,
  //           top: yPos + "px",
  //           left: xPos + "px",
  //           maxWidth:'100vw',
  //         }}
  //       />
  //     );
  //   }
  //   return particles;
  // }
  return (
    <>
      <Container>
        {/* Particles */}
        {/* {generateParticles()} */}

        <Row style={{ marginTop: "2rem" }}>
          <Col id="hero2" md={6} lg={6} xs={12} sm={12}>
            <Row>
              <Col id="hero1" className="animate__animated animate__zoomIn">
                {" "}
                Empower Dreams <br /> with <span id="hero6"> AI Companion.</span>
              </Col>
            </Row>
            <Row>
              <Col id="hero3">
                Activate live segments and precisely target individuals based on
                their behaviors for tailored messaging.
              </Col>
            </Row>
            <Row>
              <Col>
                <Button id="hero4" onClick={() => navigate("/comingsoon")}>
                  {" "}
                  <SiCodemagic /> Get started with AI
                </Button>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col className="animate__animated animate__slideInRight" id="hero5">
                <img src={heroimage1} alt="Hero" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Hero;
