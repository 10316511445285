import React from 'react'
import "./Header.css"
import Container  from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import { Row, Col, Button } from "react-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../../Assets/Service/FuLogo.svg"
function Header() {
  return (
   <>
   <Container fluid id="header1"> 
      <Navbar>
  <Container>
    <Navbar.Brand><img src={logo} alt="Logo"/></Navbar.Brand>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      {/* <Nav className="me-auto">
        <Nav.Link href="#home">Home</Nav.Link>
        <Nav.Link href="#link">Link</Nav.Link>
        <NavDropdown title="Dropdown" id="basic-nav-dropdown">
          <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
          <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
        </NavDropdown>
      </Nav> */}
      <Nav className="ml-auto">
        <Nav.Link href="/comingsoon"> <Button id="header2" > Login</Button></Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>
</Container>
   </>
  )
}

export default Header