import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Hero from "../../components/Hero/Hero";
import "./Home.css";
import 'animate.css';
import ServiceCard from "../../components/ServiceCard/ServiceCard";
import figma from ".././../Assets/Service/figma.png";
import illustration from ".././../Assets/Service/Illustration.png";
import retouching from ".././../Assets/Service/Retouching.png";
import stock from ".././../Assets/Service/Stock.png";
import text from ".././../Assets/Service/Text.png";
import trading from ".././../Assets/Service/trading.png";
import Projectcard from "../../components/Projectcard/Projectcard";
import project1 from "../../Assets/Service/project1.png"
import project2 from "../../Assets/Service/project2.png"
import project3 from "../../Assets/Service/project3.png"
import project4 from "../../Assets/Service/project4.png"
import PriceCard from "../../components/PriceCard/PriceCard";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
function Home() {
  return (
    <>
    <Header/>
      <Container>
        <Row>
          <Col>
            {" "}
            <Hero />
          </Col>
        </Row>
      </Container>
      <Container fluid id="home1">
        <Container>
          <Row>
            {" "}
            <Col>
              <Row>
                {" "}
                <Col id="home2"> What we are best in </Col>
              </Row>
              <Row>
                {" "}
                <Col id="home3">AI for Image Processing & Trading</Col>
              </Row>
              <Row>
                <Col id="home4">
                  {" "}
                  Harnessing the Power of AI to Revolutionize Design Services
                </Col>
              </Row>
              <Row id="home5">
                <Col md={4} lg={4} sm={12} xs={12} id="servicecardcol">
                  {" "}
                  <ServiceCard
                    title="AI Figma Generator"
                    description="AI Figma generators automate design tasks, enhancing capabilities by generating variations and streamlining workflows, fostering productivity and creativity in UI/UX Design"
                    image={figma}
                  />{" "}
                </Col>
                <Col md={4} lg={4} sm={12} xs={12} id="servicecardcol">
                  {" "}
                  <ServiceCard
                    title="AI Illustration"
                    description="AI illustration tools empower artists and designers to create intricate, lifelike illustrations with ease, offering a wide range of styles and effects to enhance visual storytelling and design projects."
                    image={illustration}
                  />{" "}
                </Col >
                <Col md={4} lg={4} sm={12} xs={12} id="servicecardcol">
                  {" "}
                  <ServiceCard
                    title="Text to image AI"
                    description="Text-to-image AI technology converts textual descriptions into visual representations, allowing for the generation of images based on written input, facilitating rapid prototyping, and aiding in creative content creation."
                    image={text}
                  />{" "}
                </Col>
              </Row>
              <Row id="home5">
                <Col md={4} lg={4} sm={12} xs={12} id="servicecardcol">
                  {" "}
                  <ServiceCard
                    title="AI Retouching"
                    description="
                  
AI retouching technology transforms images, enhancing them with precision and efficiency, enabling seamless editing and creative refinement, thereby elevating visual content with unparalleled ease and sophistication."
                    image={retouching}
                  />{" "}
                </Col>
                <Col md={4} lg={4} sm={12} xs={12} id="servicecardcol">
                  {" "}
                  <ServiceCard
                    title="AI Stock Images"
                    description="AI image enhancement optimizes stock photos, refining them with precision and speed, ensuring high-quality visuals for diverse projects, thereby streamlining content creation processes and enhancing visual impact."
                    image={stock}
                  />{" "}
                </Col>
                <Col>
                  {" "}
                  <ServiceCard
                    title="Trading AI"
                    description="AI trading algorithms leverage data analytics and machine learning to automate trading decisions, optimizing strategies for maximum returns, minimizing risks."
                    image={trading}
                  />{" "}
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid id="home9">
        <Container>
          <Row>
            <Col md={6} lg={6} xs={12} sm={12}>
              <Row>
                <Col id="home6"> About us</Col>
              </Row>
              <Row>
                <Col id="home8">
                  {" "}
                  Pioneering AI Solutions for Today's Challenges
                </Col>
              </Row>
              <Row>
                <Col id="home7">
                  {" "}
                  At Fugen AI, we are at the forefront of innovation, pioneering
                  groundbreaking AI solutions to address the complex challenges
                  of our time. With a relentless commitment to excellence and a
                  passion for pushing the boundaries of what's possible, we
                  leverage cutting-edge technology to drive positive change
                  across diverse industries. Our team of experts at Fugen AI is
                  dedicated to developing and implementing AI-driven solutions
                  that empower businesses and individuals alike. Whether it's
                  revolutionizing trading strategies or enhancing the visual
                  landscape through image-type AI, we strive to deliver
                  transformative solutions that optimize efficiency, maximize
                  potential, and inspire creativity. With a focus on integrity,
                  collaboration, and innovation, Fugen AI is driven by a
                  singular mission: to harness the power of artificial
                  intelligence to create a brighter, more sustainable future for
                  all. Join us on this journey as we continue to push the
                  boundaries of possibility and shape the future of AI-driven
                  innovation.
                </Col>
              </Row>
            </Col>
            <Col md={1} lg={1}></Col>
            <Col>
              <Row>
                <Col id="home10">
                  <Row>
                    <Col id="home11"> Get a free Keystroke quote now</Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        style={{ marginBottom: "1rem" }}
                      >
                        <Form.Label id="home12">Name</Form.Label>
                        <Col>
                          <Form.Control placeholder="Your name" id="home13" />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        style={{ marginBottom: "1rem" }}
                      >
                        <Form.Label id="home12">Email</Form.Label>
                        <Col>
                          <Form.Control placeholder="example@gmail.com" id="home13" />
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        style={{ marginBottom: "1rem" }}
                      >
                        <Form.Label id="home12">Phone number</Form.Label>
                        <Col>
                          <Form.Control placeholder="+123456789" id="home13" />
                        </Col>
                      </Form.Group>
                      <Button id="home14">Get a Free Quote</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid id="home15">
        <Container >
          <Row style={{paddingTop:'3rem',paddingBottom:'3rem'}}>
            <Col> 
            <Row><Col id="home16"> Our Projects</Col></Row>
            <Row><Col id="home17"> Some of our <br/> Finest Works</Col></Row>
            </Col>
          </Row>
          <Row> <Col md={6} lg={6} sm={12} xs={12}> <Projectcard image={project1}/> </Col>
          <Col md={6} lg={6} sm={12} xs={12}> <Projectcard image={project2} /> </Col>
          </Row>
          <Row id="home18" style={{paddingBottom:'3rem'}}> <Col> <Projectcard image={project3}/> </Col>
          <Col md={6} lg={6} sm={12} xs={12}> <Projectcard image={project4} /> </Col>
          </Row>
        </Container>

      </Container>

      <Container fluid id="home19" style={{paddingBottom:'3rem'}}>
<Container>
  <Row>
    <Col id="home20">
    Pricing plan
    </Col>
  </Row>
  <Row>
    <Col id="home21">
    We’ve built solutions for...
    </Col>
  </Row>
  <Row>
    <Col id="home22"> Flexible pricing options for freelancers and design teams.</Col>
  </Row>
  <Row >
    <Col md={4} lg={4} sm={12} xs={12} id="pricecardcol"> <PriceCard 
    planname="Fugen AI Free"
    description="Get the information you’re looking for, create original content, and get things done fast."
    price="Free"
    buttonname="Try it now"
    lineone="Image Processing AI"
    linetwo="Image Enhancement AI"
    linethree="AI Trading"
    linefour="Ideal for individuals and small businesses looking to explore AI capabilities."
    /> </Col>
    <Col md={4} lg={4} sm={12} xs={12} id="pricecardcol"> <PriceCard
    planname="Fugen AI Pro"
    description="Get the information you’re looking for, create original content, and get things done fast."
    price="$39.00"
    buttonname="Learn More"
    lineone="Advanced Image Processing AI"
    linetwo="Premium Image Enhancement AI"
    linethree="Advance AI Trading"
    linefour="Perfect for professionals and growing businesses requiring advanced AI features."
    /> </Col>
    <Col id="pricecardcol"> <PriceCard
    planname="Fugen AI Corporate"  
    description="Get the information you’re looking for, create original content, and get things done fast."
    price="$49.00"
    buttonname="Learn More"  
    lineone="Enterprise-grade Image Processing AI"
    linetwo="Enterprise-level Image Enhancement AI"
    linethree="Customized AI Trading"
    linefour=" Scalable AI solutions with personalized support and customization options."
    /> </Col>
  </Row>
</Container>

      </Container>
      <Footer/>
    </>
  );
}

export default Home;
