import React from 'react'
import "./Footer.css"
import { Container, Row, Col, Button } from "react-bootstrap";
import { CiLocationOn } from 'react-icons/ci';
import { IoCallOutline } from "react-icons/io5";
function Footer() {
  return (
  <>
  <Container style={{paddingTop:'2rem', paddingBottom:'2rem'}}>
  {/* <hr id="pricecard6"/> */}
  <Row><Col id="footer3"> <CiLocationOn id="footer5"/> Singapore Office Address: #07-115,
Block 979C, <br/>
Buangkok Crescent,
Singapore-533979</Col></Row>
<Row><Col id="footer4"> <IoCallOutline id="footer5"/> +6590500429</Col></Row>
  <Row><Col id="footer1" md={6} lg={6} xs={12} sm={12}> © 2024. All rights reserved by Fugen Ai.</Col> 
  <Col id="footer2" md={6} lg={6} xs={12} sm={12}> Privacy Policy • Terms of Use </Col>
  </Row>
  </Container>
  </>
  )
}

export default Footer