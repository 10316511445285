import React from 'react'
import "./Projectcard.css"
import { Container, Row, Col } from "react-bootstrap";
function Projectcard(props) {
  return (
    <>
    <Container>
        <Row id="projectcard1">
            <Col>
            <Row><Col id="projectcard2"> <img src={props.image}  id="projectcard3" /> </Col></Row>
            
            </Col>
        </Row>
    </Container>
    </>
  )
}

export default Projectcard