import React from "react";
import "./ServiceCard.css";
import { Container, Row, Col } from "react-bootstrap";
import "./ServiceCard.css";
import { useNavigate } from "react-router-dom";
function ServiceCard(props) {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Row id="servicecard1">
          <Col>
            <Row>
              <Col md={4} lg={4} xs={4} sm={4}>
                {" "}
                <img src={props.image} />{" "}
              </Col>{" "}
              <Col id="servicecard2"> {props.title}</Col>
            </Row>
            <Row>
              <Col id="servicecard3"> {props.description}</Col>
            </Row>
            <Row>
              <Col id="servicecard4" onClick={() => navigate("/comingsoon")}>
                {" "}
                Know more
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ServiceCard;
