import React from 'react'
import "./Comingsoon.css"
import { Container, Row, Col } from "react-bootstrap";
import brain from "../../Assets/Service/brain.png"
function Comingsoon() {
  return (
  <>
  <Container fluid id="comingsoon1">
 <Row id="comingsoon3">
    <Col style={{display:'flex', alignItems:'center', justifyContent:'center', marginTop:'2rem'}}> 
    <img src={brain} id="comingsoon2"/>
    </Col>
 </Row>
 <Row> <Col id="comingsoon4"> We are Launching soon</Col></Row>
 <Row><Col id="comingsoon5"> AI website is in the pre-launch phase and encourages visitors to stay informed about the upcoming <br/> release of your innovative AI services.</Col></Row>
 <Row><Col id="comingsoon5" style={{marginTop:"1rem", fontSize:'1.2rem'}}> Stay Tuned </Col></Row>
  </Container>
  </>
  )
}

export default Comingsoon