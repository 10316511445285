import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from '../pages/Home/Home';
import { NavConstants } from './NavConstants';
import Login from '../pages/Login/Login';
import Comingsoon from '../pages/ComingSoon/Comingsoon';
function RootRouter() {
  return (
  <>
  <Router>
  <Routes>
          <Route path={NavConstants.home} element={<Home />} />
          <Route path={NavConstants.login} element={<Login />} />
          <Route path={NavConstants.comingsoon} element={<Comingsoon/>} />
        </Routes>
  </Router>
  </>
  )
}

export default RootRouter