import React from "react";
import "./PriceCard.css";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
function PriceCard(props) {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Row>
          <Col id="pricecard1">
            <Row>
              <Col id="pricecard2">{props.planname} </Col>
            </Row>
            <Row>
              <Col id="pricecard3">
               {props.description}
              </Col>
            </Row>
<hr id="pricecard6"/>
            <Row>
              <Col > <span id="pricecard4"> {props.price} </span> <br/> <span id="pricecard9" style={{marginBottom:"2rem"}}> user/month</span></Col>
            </Row>

            <Row>
              <Col>
                {" "}
                <Button id="pricecard5" onClick={() => navigate("/comingsoon")}> {props.buttonname} </Button>
              </Col>
            </Row>
            <Row>
              <Col id="pricecard7"> Services includes</Col>
            </Row>
            <Row>
              {" "}
              <Col>
              <ul style={{paddingLeft: "20px"}} id="pricecard8">
                  <li> {props.lineone}</li>
                  <li>{props.linetwo}</li>
                  <li> {props.linethree}</li>
                  <li>
                    {" "}
                    {props.linefour}
                  </li>
                  {/* <li>{props.linefive}</li>
                  <li>{props.linesix}</li> */}
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default PriceCard;
