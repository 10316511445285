import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "./Login.css"; // Import your custom CSS file for styling
import logo from "../../Assets/Service/FuLogo.svg";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

function Login() {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container fluid id="login1">
      <Container>
        <Row id="login3">
          <Col id="login2" md={4} lg={4} xs={12} sm={12}>
            <Row>
              {" "}
              <Col id="login5">
                {" "}
                <img src={logo} id="login4" alt="Logo" />
              </Col>
            </Row>
            <Row>
              <Col id="login6"> Login</Col>
            </Row>
            <Row>
              <Col id="login7">
                {" "}
                Welcome back to Fusion AI! Your gateway to innovation and
                insights.
              </Col>
            </Row>
            <Row>
              {" "}
              <Col>
                {" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label id="login8">Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    id="login9"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {" "}
              <Col>
                {" "}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label id="login8">Password</Form.Label>
                  <div className="password-input">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter password"
                      id="login12"
                    />
                    {showPassword ? (
                      <FaRegEyeSlash
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      />
                    ) : (
                      <FaRegEye
                        className="eye-icon"
                        onClick={togglePasswordVisibility}
                      />
                    )}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                {" "}
                <Button id="login10"> Login</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Login;
