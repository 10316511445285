
import "./App.css";

import RootRouter from "./navigations/RootRouter";

function App() {
  return (
    <>
      
   
      <RootRouter />
    </>
  );
}

export default App;
